import { render, staticRenderFns } from "./AnonymousConfirmation.vue?vue&type=template&id=2ff0cf55&"
import script from "./AnonymousConfirmation.vue?vue&type=script&lang=js&"
export * from "./AnonymousConfirmation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.stad-antwerpen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BCard, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BCard, BRow, BContainer})
    

export default component.exports